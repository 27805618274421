import { Flex, LinkBox, Stack, Text } from '@chakra-ui/react';
import { LogCategories, sendClickLog } from 'libs/gtag';
import Link from 'next/link';
import { paths } from 'constants/paths';
import ResponsiveButton from 'components/Shared/ResponsiveButton';

const TopButton = () => (
  <Stack
    direction={{ base: 'column', md: 'column', lg: 'row' }}
    align="center"
    justify="space-around"
    width="100%"
    spacing={{ base: '1rem', md: '1rem', lg: '2rem' }}
  >
    <LinkBox
      width="100%"
      onClick={() => {
        sendClickLog({
          category: LogCategories.TOP,
          label: '資料ダウンロード',
          value: 'first_view',
        });
      }}
    >
      <Link href={paths.inquiryServiceDocument} passHref>
        <ResponsiveButton w="full">
          <Flex direction="column">
            <Text>資料ダウンロード</Text>
          </Flex>
        </ResponsiveButton>
      </Link>
    </LinkBox>
  </Stack>
);

export default TopButton;
