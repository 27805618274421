import AoyamaBookCenter from 'public/images/logos/abc.png';
import ApaHotel from 'public/images/logos/apa.png';
import Casie from 'public/images/logos/casie.png';
import Homeal from 'public/images/logos/homeal.png';
import Mederi from 'public/images/logos/mederi.png';
import OverE from 'public/images/logos/overe.png';
import SilverLife from 'public/images/logos/silverlife.png';
import Smaluna from 'public/images/logos/smaluna.png';
import SeibuSogo from 'public/images/logos/sogo.png';
import Sparty from 'public/images/logos/sparty.png';
import SPoolLogistics from 'public/images/logos/spool.png';
import Tanp from 'public/images/logos/tanp.png';
import Tential from 'public/images/logos/tential.png';
import Toysub from 'public/images/logos/toysub.png';
import TreeOfLife from 'public/images/logos/treeoflife.png'; // 生活の木
import Zenb from 'public/images/logos/zenb.png';

const ExternalLogo = {
  AoyamaBookCenter,
  ApaHotel,
  Casie,
  Homeal,
  Mederi,
  OverE,
  SilverLife,
  Smaluna,
  SeibuSogo,
  Sparty,
  SPoolLogistics,
  Tanp,
  Tential,
  Toysub,
  TreeOfLife,
  Zenb,
};

export default ExternalLogo;

export { ExternalLogo };
