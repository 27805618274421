import { Button, ButtonProps, useBreakpointValue } from '@chakra-ui/react';

const ResponsiveButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'sm', lg: 'md' });
  return (
    <Button {...rest} size={buttonSize}>
      {children}
    </Button>
  );
};

export default ResponsiveButton;
