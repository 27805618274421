import { AspectRatio, Box, Flex, LinkBox, LinkOverlay, Text, Center } from '@chakra-ui/react';
import { paths } from 'constants/paths';
import { LogCategories, sendClickLog } from 'libs/gtag';
import { Article } from 'libs/microcms';

interface Props {
  articles: Article[];
}

const columnCount = 2;

const Cases = ({ articles }: Props) => {
  const articleComponents = articles.map((article) => {
    const articleComponent = (
      <LinkBox
        key={article.id}
        onClick={() => {
          sendClickLog({
            category: LogCategories.TOP,
            label: article.title,
          });
        }}
        _hover={{
          opacity: 0.8,
        }}
      >
        <Flex
          direction="column"
          align="start"
          alignItems="start"
          width={{
            base: 'auto',
            md: '100%',
            lg: 496,
          }}
          gap={0}
        >
          <LinkOverlay href={paths.blogDetail({ blogFriendlyId: article.slug })} width="100%">
            <Center>
              <AspectRatio
                margin="0 8px"
                position="relative"
                display="flex"
                justifyContent="center"
                maxWidth="512px"
                ratio={40 / 21}
                width={{
                  base: '100%',
                  md: '100%',
                  lg: 496,
                }}
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img style={{ borderRadius: '16px' }} src={article.image.url} alt={article.title} />
              </AspectRatio>
            </Center>
            <Box marginY="1rem">
              <Text
                textStyle="subtitle"
                align={{
                  base: 'center',
                  md: 'center',
                  lg: 'left',
                }}
              >
                {article.title}
              </Text>
            </Box>
          </LinkOverlay>
          {article.tags && (
            <Flex
              direction="row"
              align="center"
              justify={{
                base: 'center',
                md: 'center',
                lg: 'start',
              }}
              flexWrap="wrap"
              width="100%"
              margin="-4px"
            >
              {article.tags.map((tag) => (
                <Box
                  margin="4px"
                  key={tag}
                  paddingX="8px"
                  paddingY="4px"
                  borderRadius="8px"
                  backgroundColor="textGray.4"
                >
                  <Text textStyle="body2" align="center" color="textGray.87">
                    {tag}
                  </Text>
                </Box>
              ))}
            </Flex>
          )}
        </Flex>
      </LinkBox>
    );
    return articleComponent;
  });

  // articleComponentsをcolumnCountの件数毎にFlexで囲む
  const articleComponentGroups = [];
  for (let i = 0; i < articleComponents.length; i += columnCount) {
    articleComponentGroups.push(
      <Flex
        key={i}
        direction={{ base: 'column', md: 'column', lg: 'row' }}
        align={{ base: 'center', md: 'center', lg: 'flex-start' }}
        justify={{ base: 'center', md: 'center', lg: 'start' }}
        flexWrap="wrap"
        gap={8}
        width={{
          base: '100%',
          md: '100%',
          lg: '64rem',
        }}
        maxW={{
          base: 512,
          md: 512,
          lg: '64rem',
        }}
      >
        {articleComponents.slice(i, i + columnCount)}
      </Flex>,
    );
  }

  return (
    <Flex gap={8} direction="column" align="center">
      {articleComponentGroups}
    </Flex>
  );
};

export default Cases;
