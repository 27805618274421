import { AspectRatio, Center, Flex, Text, Stack } from '@chakra-ui/react';
import Image from 'next/legacy/image';
import CostDown20Percentage from 'public/images/cost_down_20_percentage.svg';
import ContactExport from 'public/images/contact_export.svg';
import CustomerSatisfaction from 'public/images/customer_satisfaction.svg';

import DownIcon from 'public/icons/service-description-down.svg';
import TransporterIcon from 'public/icons/service-description-transporter.svg';
import BoxIcon from 'public/icons/service-description-box.svg';

const descriptionImageWidth = { base: '88px', md: '88px', lg: '168px' };
const descriptionImageHeight = { base: '52px', md: '52px', lg: '82px' };

const descriptions: Array<{
  key: string;
  src: string;
  spSrc: string;
  description: string;
  alt: string;
  ratio: number;
  width: Record<string, string>;
  height: Record<string, string>;
}> = [
  {
    key: 'cost_down',
    src: CostDown20Percentage,
    spSrc: DownIcon,
    description: '平均20%の\nコストダウン',
    alt: '20%コストダウンアイコン',
    ratio: 168 / 82,
    width: descriptionImageWidth,
    height: descriptionImageHeight,
  },
  {
    key: 'contact_export',
    src: ContactExport,
    spSrc: TransporterIcon,
    description: '物流視点も踏まえた\n仕様のご提案',
    alt: '仕様のご提案アイコン',
    ratio: 168 / 100,
    width: descriptionImageWidth,
    height: descriptionImageHeight,
  },
  {
    key: 'customer_satisfaction',
    src: CustomerSatisfaction,
    spSrc: BoxIcon,
    description: '外装段ボールから\n個装パッケージまで',
    alt: '製造工場アイコン',
    ratio: 176 / 93,
    width: descriptionImageWidth,
    height: descriptionImageHeight,
  },
];

const ServiceDescriptions = () => (
  <Flex
    direction={{ base: 'column', md: 'column', lg: 'row' }}
    align="center"
    justify={{ base: 'center', md: 'center', lg: 'center' }}
    gap={{ base: 3, md: 3, lg: 16 }}
    width="100%"
  >
    {descriptions.map(({ key, src, spSrc, width, height, ratio, description, alt }) => (
      <Stack
        key={key}
        direction={{ base: 'row', md: 'row', lg: 'column' }}
        align="center"
        justify={{ base: 'center', md: 'center', lg: 'space-between' }}
        spacing={{ base: 1, md: 1, lg: 4 }}
        width={{
          base: '280px',
          md: '280px',
          lg: 'auto',
        }}
      >
        <Center height={height} display={{ base: 'none', md: 'none', lg: 'inherit' }}>
          <AspectRatio ratio={ratio} width={width}>
            <Image src={src} layout="fill" alt={alt} />
          </AspectRatio>
        </Center>
        <Center display={{ base: 'inherit', md: 'inherit', lg: 'none' }}>
          <AspectRatio
            ratio={1}
            width="1.5rem"
            height="1.5rem"
            display={{ base: 'inherit', md: 'inherit', lg: 'none' }}
          >
            <Image src={spSrc} layout="fill" alt={alt} />
          </AspectRatio>
        </Center>
        <Flex direction="column">
          <Text
            align={{ base: 'center', md: 'center', lg: 'center' }}
            whiteSpace={{ base: 'nowrap', md: 'nowrap', lg: 'pre' }}
            lineHeight="150%"
            letterSpacing={0.2}
          >
            {description}
          </Text>
        </Flex>
      </Stack>
    ))}
  </Flex>
);

export default ServiceDescriptions;
