import Head from 'next/head';
import { FC } from 'react';
import TopOGP from 'public/images/top_ogp.png';
import ShizaiIcon from 'public/icons/shizai_icon.png';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  joinOriginToImage?: boolean;
}

const defaultDescription =
  'shizaiは段ボールから、紙器や包装フィルムなどの商品パッケージまで、あらゆる梱包・包装資材の作成をサポートします。品質はそのままで平均20%のコスト削減を実現。';
const DefaultHead: FC<Props> = ({
  title,
  description = defaultDescription,
  image,
  url,
  joinOriginToImage = false,
}) => {
  let imageUrl = image;
  if (joinOriginToImage && image) {
    const protocol = process.env.ORIGIN_PROTOCOL ?? 'https';
    const host = process.env.ORIGIN ?? 'localhost:5001';
    const builder = new URL(`${protocol}://${host}/`);
    builder.pathname = image;
    imageUrl = builder.toString();
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={`https://${url}`} />
      <meta property="og:image" content={imageUrl || `https://${process.env.ORIGIN}${TopOGP}`} />
      <meta property="og:site_name" content="shizai" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl || `https://${process.env.ORIGIN}${TopOGP}`} />
      <meta name="apple-mobile-web-app-title" content="shizai" />
      <link rel="apple-touch-icon" sizes="192x192" href={ShizaiIcon} />
      <link rel="icon" type="image/png" href="/icons/favicon.ico" />
      {url && <link rel="canonical" href={`https://${url}`} />}
    </Head>
  );
};

export default DefaultHead;
