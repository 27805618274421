import { InferGetStaticPropsType } from 'next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {
  Text,
  Box,
  Flex,
  Button,
  LinkBox,
  LinkOverlay,
  Stack,
  Center,
  AspectRatio,
} from '@chakra-ui/react';

import { LogCategories, sendClickLog } from 'libs/gtag';
import { layouts } from 'constants/layouts';
import { blogs } from 'constants/links';
import { paths } from 'constants/paths';
import DefaultHead from 'components/DefaultHead';
import ResponsiveButton from 'components/Shared/ResponsiveButton';
import Cases from 'components/Top/Cases';
import ServiceDescriptions from 'components/Top/ServiceDescriptions';

import TopHeroBackground from 'public/images/top_hero_bg.png';
import TopKeyVisualSP from 'public/images/top_key_visual_sp.png';
import TopHeroTitle from 'public/images/top_hero_title.svg';
import TopCustomerCount from 'public/images/top_customer_count.svg';
import TopCustomerSatisfaction from 'public/images/top_customer_satisfaction.svg';
import CustomerVoiceIcon from 'public/icons/customer_voice.svg';
import PackageIcon from 'public/icons/package_icon.svg';
import MagnifyingGlassIcon from 'public/icons/magnifying_glass_icon.svg';
import PackageItemList from 'public/images/package_item_list.png';
import PackageItemListSP from 'public/images/package_item_list_sp.png';
import CostDownImage from 'public/images/cost_down_image.svg';
import ImprovementImage from 'public/images/improvement_image.svg';
import AllAtOnceImage from 'public/images/all_at_once_image.svg';
import SustainableImage from 'public/images/sustainable_image.svg';
import LookForPackageImage from 'public/images/look_for_package_image.svg';
import ContactExpertImage from 'public/images/contact_expert_illustration.svg';
import TopButton from 'components/Top/TopButton';
import { getArticles } from 'libs/microcms';
import { ExternalLogo } from 'components/Logo/ExternalLogo';

const CUSTOMER_VOICE_COUNT = 4;

export const getStaticProps = async () => {
  const { contents: articles } = await getArticles({
    limit: CUSTOMER_VOICE_COUNT,
    orders: '-publishedAt',
    filters: 'isFeatured[equals]true',
  });

  return {
    props: {
      layout: layouts.default,
      articles,
    },
  };
};

const SectionSpacer = () => (
  <Box
    mt={{
      base: '4.5rem',
      md: '4.5rem',
      lg: '4.5rem',
    }}
  />
);

const descriptionImageWidth = { base: '100%', md: '100%', lg: '496px' };

const Index = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <Box overflowX={{ base: 'hidden', md: 'hidden', lg: 'initial' }}>
      <DefaultHead
        title="shizai - あらゆる資材のコスト・仕様を最適化"
        url={process.env.ORIGIN + paths.root}
      />
      <Box position="relative" height={{ base: 'fit-content', md: 'fit-content', lg: '668px' }}>
        <Box
          id="pc-top-hero-background-image"
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          width={1750}
          height={708}
          display={{
            base: 'none',
            md: 'none',
            lg: 'block',
          }}
        >
          <AspectRatio
            ratio={1750 / 708}
            overflowX="hidden"
            minWidth={1750}
            maxWidth={1750}
            width={1750}
            height={708}
            minHeight={708}
            maxHeight={708}
          >
            <Image src={TopHeroBackground} layout="fixed" />
          </AspectRatio>
        </Box>
        <Flex
          top={0}
          left="50%"
          transform="translateX(-50%)"
          position={{ base: 'relative', md: 'relative', lg: 'absolute' }}
          overflowX="hidden"
          maxWidth="100vw"
          width="100vw"
          direction="row"
          height="fit-content"
          justify="center"
          align="flex-start"
        >
          <Flex
            as="section"
            justify="start"
            align="center"
            direction="column"
            height={{
              base: 'fit-content',
              md: 'fit-content',
              lg: 668,
            }}
            maxWidth="100%"
            mt={{ base: '0.75rem', md: '0.75rem', lg: '4rem' }}
          >
            <Text color="textGray.87" fontSize={{ base: 'sm', md: 'sm', lg: '2xl' }} align="center">
              全国数百以上の一次製造工場ネットワークで
            </Text>
            <Box
              display={{
                base: 'none',
                md: 'none',
                lg: 'block',
              }}
              mt="1.5rem"
            >
              <Image
                src={TopHeroTitle}
                width={680}
                height={169}
                layout="fixed"
                alt="あらゆる資材のコスト・仕様を最適化"
                title="あらゆる資材のコスト・仕様を最適化"
                priority
              />
            </Box>
            <AspectRatio
              mt={2}
              ratio={556 / 326}
              overflowX="hidden"
              minWidth="556px"
              maxWidth="1024px"
              width="100vw"
              display={{
                base: 'block',
                md: 'block',
                lg: 'none',
              }}
            >
              <Image src={TopKeyVisualSP} layout="fill" priority alt="スマホ向けヒーロー画像" />
            </AspectRatio>
            <Box mt={{ base: 2, md: 2, lg: 6 }} />
            <ServiceDescriptions />
            <Box
              paddingX={{ base: '1.5rem', md: '1.5rem', lg: 0 }}
              width={{ base: '100%', md: '100%', lg: 'auto' }}
              mt={{ base: 6, md: 6, lg: 8 }}
              maxW={{
                base: '512px',
                md: '512px',
                lg: 'auto',
              }}
            >
              <TopButton />
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Flex direction="column" gap="4.5rem" mt={{ base: 12, md: 12, lg: 0 }}>
        <Flex
          as="section"
          direction="column"
          justify="center"
          align="center"
          gap={{ base: 4, md: 4, lg: 6 }}
        >
          <Flex justify="center" align="center" gap={{ base: 3, md: 3, lg: 6 }}>
            <AspectRatio
              ratio={272 / 114}
              minW={148}
              maxW={272}
              width={{
                base: '40vw',
                md: '40vw',
                lg: 272,
              }}
            >
              <Image src={TopCustomerCount} priority alt="ご利用企業数450社突破 ※2023年9月時点" />
            </AspectRatio>

            <AspectRatio
              ratio={238 / 114}
              minWidth={128}
              maxWidth={238}
              width={{
                base: '34vw',
                md: '34vw',
                lg: 238,
              }}
            >
              <Image src={TopCustomerSatisfaction} priority alt="顧客満足度約95% ※2021年自社調査" />
            </AspectRatio>
          </Flex>

          <Flex direction="column" justify="center" align="center" gap={1}>
            <Text textStyle="h2" fontSize={{ base: '0.875rem', md: '0.875rem', lg: 34 }}>
              EC・D2Cだけではなく、メーカーや物流倉庫まで
            </Text>
            <Text color="textGray.60" fontSize={{ base: '0.75rem', md: '0.75rem', lg: 20 }}>
              幅広いお客さまにご利用いただいています
            </Text>
          </Flex>

          <Flex
            px={{
              base: 6,
              md: 6,
              lg: 0,
            }}
            width="100%"
            justify="space-between"
            align="center"
            alignContent="center"
            gap={{
              base: '2vw',
              md: '2vw',
              lg: 4,
            }}
            rowGap={{
              base: 6,
              md: 6,
              lg: 8,
            }}
            flexWrap="wrap"
            display={{
              base: 'flex',
              md: 'flex',
              lg: 'flex',
            }}
            maxW={{
              base: 512,
              md: 512,
              lg: '64rem',
            }}
          >
            <AspectRatio
              ratio={384 / 85}
              maxWidth={{
                base: 101.376, // スマホ/タブレットの最大横幅 * (view width / 100) == 512 * (19.8 / 100) で最大横幅を計算
                md: 101.376,
                lg: 128,
              }}
              width={{
                base: '19.8vw', // (Figmaのアイコンの横幅 / FigmaのSPの横幅) * 100 == (74 / 375) * 100 でview widthを算出
                md: '19.8vw',
                lg: 128,
              }}
              order={{
                base: 1,
                md: 1,
                lg: 1,
              }}
            >
              <Image src={ExternalLogo.Zenb} alt="お客様ロゴ:ZENB" />
            </AspectRatio>

            <AspectRatio
              ratio={544 / 130}
              maxWidth={{
                base: 133.632,
                md: 133.632,
                lg: 181,
              }}
              width={{
                base: '26.1vw',
                md: '26.1vw',
                lg: 181,
              }}
              order={{
                base: 2,
                md: 2,
                lg: 2,
              }}
            >
              <Image src={ExternalLogo.Smaluna} alt="お客様ロゴ:スマルナ" />
            </AspectRatio>

            <AspectRatio
              ratio={487 / 123}
              maxWidth={{
                base: 123.904,
                md: 123.904,
                lg: 162,
              }}
              width={{
                base: '24.2vw',
                md: '24.2vw',
                lg: 162,
              }}
              order={{
                base: 3,
                md: 3,
                lg: 3,
              }}
            >
              <Image src={ExternalLogo.TreeOfLife} alt="お客様ロゴ:生活の木" />
            </AspectRatio>

            <AspectRatio
              ratio={517 / 109}
              maxWidth={{
                base: 123.392,
                md: 123.392,
                lg: 172,
              }}
              width={{
                base: '24.1vw',
                md: '24.1vw',
                lg: 172,
              }}
              order={{
                base: 4,
                md: 4,
                lg: 4,
              }}
            >
              <Image src={ExternalLogo.SPoolLogistics} alt="お客様ロゴ:エスプールロジスティクス" />
            </AspectRatio>

            <AspectRatio
              ratio={630 / 81}
              maxWidth={{
                base: 153.088,
                md: 153.088,
                lg: 210,
              }}
              width={{
                base: '29.9vw',
                md: '29.9vw',
                lg: 210,
              }}
              order={{
                base: 5,
                md: 5,
                lg: 5,
              }}
            >
              <Image src={ExternalLogo.SeibuSogo} alt="お客様ロゴ:西武そごう" />
            </AspectRatio>

            <AspectRatio
              ratio={483 / 102}
              maxWidth={{
                base: 117.76,
                md: 117.76,
                lg: 161,
              }}
              width={{
                base: '23vw',
                md: '23vw',
                lg: 161,
              }}
              order={{
                base: 6,
                md: 6,
                lg: 6,
              }}
            >
              <Image src={ExternalLogo.Tential} alt="お客様ロゴ:TENTIAL" />
            </AspectRatio>

            <AspectRatio
              ratio={637 / 144}
              maxWidth={{
                base: 152.934,
                md: 152.934,
                lg: 212,
              }}
              width={{
                base: '29.87vw',
                md: '29.87vw',
                lg: 212,
              }}
              order={{
                base: 7,
                md: 7,
                lg: 8,
              }}
            >
              <Image src={ExternalLogo.SilverLife} alt="お客様ロゴ:シルバーライフ" />
            </AspectRatio>

            <AspectRatio
              ratio={732 / 117}
              maxWidth={{
                base: 160.256,
                md: 160.256,
                lg: 243.488,
              }}
              width={{
                base: '31.3vw',
                md: '31.3vw',
                lg: 243.488,
              }}
              order={{
                base: 8,
                md: 8,
                lg: 9,
              }}
            >
              <Image src={ExternalLogo.ApaHotel} alt="お客様ロゴ:アパホテル" />
            </AspectRatio>

            <AspectRatio
              ratio={303 / 136}
              maxWidth={{
                base: 73.216,
                md: 73.216,
                lg: 100.689,
              }}
              width={{
                base: '14.3vw',
                md: '14.3vw',
                lg: 100.689,
              }}
              order={{
                base: 9,
                md: 9,
                lg: 7,
              }}
            >
              <Image src={ExternalLogo.AoyamaBookCenter} alt="お客様ロゴ:青山ブックセンター" />
            </AspectRatio>

            <AspectRatio
              ratio={405 / 114}
              maxWidth={{
                base: 87.04,
                md: 87.04,
                lg: 134.587,
              }}
              width={{
                base: '17vw',
                md: '17vw',
                lg: 134.587,
              }}
              order={{
                base: 10,
                md: 10,
                lg: 10,
              }}
            >
              <Image src={ExternalLogo.Toysub} alt="お客様ロゴ:トイサブ" />
            </AspectRatio>

            <AspectRatio
              ratio={369 / 108}
              maxWidth={{
                base: 84.992,
                md: 84.992,
                lg: 123,
              }}
              width={{
                base: '16.6vw',
                md: '16.6vw',
                lg: 123,
              }}
              order={{
                base: 11,
                md: 11,
                lg: 11,
              }}
            >
              <Image src={ExternalLogo.Sparty} alt="お客様ロゴ:Sparty" />
            </AspectRatio>

            <AspectRatio
              ratio={445 / 75}
              maxWidth={{
                base: 112.127,
                md: 112.127,
                lg: 148,
              }}
              width={{
                base: '21.9vw',
                md: '21.9vw',
                lg: 148,
              }}
              order={{
                base: 12,
                md: 12,
                lg: 12,
              }}
            >
              <Image src={ExternalLogo.Mederi} alt="お客様ロゴ:mederi" />
            </AspectRatio>

            <AspectRatio
              ratio={322 / 85}
              maxWidth={{
                base: 77.824,
                md: 77.824,
                lg: 107,
              }}
              width={{
                base: '15.2vw',
                md: '15.2vw',
                lg: 107,
              }}
              order={{
                base: 13,
                md: 13,
                lg: 13,
              }}
            >
              <Image src={ExternalLogo.OverE} alt="お客様ロゴ:overE" />
            </AspectRatio>

            <AspectRatio
              ratio={349 / 79}
              maxWidth={{
                base: 90.112,
                md: 90.112,
                lg: 116,
              }}
              width={{
                base: '17.6vw',
                md: '17.6vw',
                lg: 116,
              }}
              order={{
                base: 14,
                md: 14,
                lg: 14,
              }}
            >
              <Image src={ExternalLogo.Tanp} alt="お客様ロゴ:TANP" />
            </AspectRatio>

            <AspectRatio
              ratio={259 / 79}
              maxWidth={{
                base: 72.192,
                md: 72.192,
                lg: 86,
              }}
              width={{
                base: '14.1vw',
                md: '14.1vw',
                lg: 86,
              }}
              order={{
                base: 15,
                md: 15,
                lg: 15,
              }}
            >
              <Image src={ExternalLogo.Casie} alt="お客様ロゴ:Casie" />
            </AspectRatio>

            <AspectRatio
              ratio={496 / 153}
              maxWidth={{
                base: 120.32,
                md: 120.32,
                lg: 183.23,
              }}
              width={{
                base: '23.5vw',
                md: '23.5vw',
                lg: 183.23,
              }}
              order={{
                base: 16,
                md: 16,
                lg: 16,
              }}
            >
              <Image src={ExternalLogo.Homeal} alt="お客様ロゴ:homeal" />
            </AspectRatio>
          </Flex>
        </Flex>

        <Flex as="section" direction="column" gap={8} px={{ base: 6, md: 6, lg: 0 }}>
          <Flex justifyContent="center" alignItems="center" gap={2}>
            <Box as="span" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <Image
                src={CustomerVoiceIcon}
                width={64}
                height={64}
                layout="fixed"
                alt="お客さまの声アイコン"
              />
            </Box>
            <Text as="h2" textStyle="h1" mt={-2}>
              お客さまの声
            </Text>
          </Flex>
          <Box
            maxW={{
              base: '512px',
              md: '100%',
              lg: 'auto',
            }}
          >
            <Cases articles={props.articles} />
          </Box>
          <Center>
            <LinkBox
              onClick={() => {
                sendClickLog({
                  category: LogCategories.TOP,
                  label: '導入実績をもっと見る',
                });
              }}
              width={{ base: '100%', md: '100%', lg: 'auto' }}
              maxW={{
                base: '512px',
                md: '512px',
                lg: 'auto',
              }}
            >
              <LinkOverlay href={paths.blog}>
                <ResponsiveButton
                  variant="outline"
                  width={{
                    base: '100%',
                    md: '100%',
                    lg: 'auto',
                  }}
                >
                  もっと見る
                </ResponsiveButton>
              </LinkOverlay>
            </LinkBox>
          </Center>
        </Flex>
      </Flex>

      <Box
        as="section"
        marginX={{ base: 'auto', md: 'auto', lg: 0 }}
        paddingX={{ base: '1.5rem', md: '1.5rem', lg: 0 }}
        maxWidth={{
          base: '512px',
          md: '512px',
          lg: '100%',
        }}
      >
        <Box mt="1.5rem" />
        <SectionSpacer />

        <Flex justify="center" align="center" direction="column">
          <Flex>
            <Box mr="1rem" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <Image src={PackageIcon} width={64} height={64} layout="fixed" />
            </Box>
            <Text as="h2" textStyle="h1">
              豊富な対応資材
            </Text>
          </Flex>
          <Text
            textStyle="body1"
            color="textGray.60"
            fontSize={{
              base: '0.75rem',
              md: '0.75rem',
              lg: '0.875rem',
            }}
            align={{
              base: 'center',
              md: 'center',
              lg: 'start',
            }}
            mt={{
              base: '1rem',
              md: '1rem',
              lg: '1.5rem',
            }}
            whiteSpace={{
              base: 'pre-wrap',
              md: 'pre-wrap',
              lg: 'nowrap',
            }}
          >
            {`箱・袋・カード・包装紙・紙管など、
            あらゆる材質・デザイン・形状・加工に対応できます。`}
          </Text>
        </Flex>
        <Box
          margin="1.5rem 0"
          display={{
            base: 'block',
            md: 'block',
            lg: 'none',
          }}
        >
          <AspectRatio ratio={327 / 267} maxWidth="100%" margin="auto">
            <Image src={PackageItemListSP} layout="fill" alt="対応資材リスト" />
          </AspectRatio>
        </Box>
        <Box
          position="relative"
          height="248px"
          marginY="4px"
          display={{
            base: 'none',
            md: 'none',
            lg: 'block',
          }}
        >
          <Flex
            justify="center"
            position="absolute"
            width="100vw"
            maxWidth="100vw"
            overflowX="hidden"
            top={0}
            left="50%"
            transform="translateX(-50%)"
          >
            <Box>
              <Image
                src={PackageItemList}
                width={1756}
                height={272}
                layout="fixed"
                alt="対応資材リスト"
              />
            </Box>
          </Flex>
        </Box>
        <Center flexDirection="column">
          <Text
            as="p"
            textStyle="h4"
            align="center"
            fontSize={{
              base: '0.875rem',
              md: '0.875rem',
              lg: '1rem',
            }}
            whiteSpace={{
              base: 'normal',
              md: 'normal',
              lg: 'pre-wrap',
            }}
          >
            {`無地だけではなく、貴社のオリジナルデザインを印刷することも可能です。
            製造は1,000ロットから、無料でお見積もりとサンプルをお出しします。`}
          </Text>
          <Box
            mt={{
              base: '1.5rem',
              md: '1.5rem',
              lg: '2rem',
            }}
          />
          <Stack
            direction={{ base: 'column', md: 'column', lg: 'row' }}
            align="center"
            justify="center"
            spacing={{ base: '1rem', md: '1rem', lg: '2rem' }}
            width="100%"
          >
            <LinkBox
              onClick={() => {
                sendClickLog({
                  category: LogCategories.TOP,
                  label: '資材一覧へ',
                });
              }}
              width={{ base: '100%', md: '100%', lg: '320px' }}
            >
              <Link href={paths.quotation} passHref>
                <ResponsiveButton variant="outline" w="full">
                  資材一覧へ
                </ResponsiveButton>
              </Link>
            </LinkBox>
          </Stack>
        </Center>

        <SectionSpacer />

        <Flex justify="center" align="center" direction="column">
          <Flex>
            <Box mr="1rem" display={{ base: 'none', md: 'none', lg: 'block' }}>
              <Image src={MagnifyingGlassIcon} width={64} height={64} layout="fixed" />
            </Box>
            <Text as="h2" textStyle="h1">
              選ばれる理由
            </Text>
          </Flex>
        </Flex>
        <Box mt={{ base: '1.5rem', md: '1.5rem', lg: '2rem' }} />
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row-reverse' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={62 / 45}>
            <Image
              src={CostDownImage}
              layout="fill"
              alt="アパレル企業のオリジナルダンボールを置き換えた事例"
            />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              数百以上の一次製造工場を熟知
              <br />
              最適な設備を持つ工場選定し
              <br />
              平均20%のコストダウンを実現
            </Text>
            <Box mt="1.5rem" />
            <Text
              as="p"
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              工場ごとに得意・不得意が存在し、同じ仕様でも工場によって価格に差が出ることはご存知ですか？
              <br />
              shizaiは全国の優良な一次製造工場の設備状況を熟知しています。製造工程や配送形態も踏まえ、貴社の資材に最適な工場を選定し、品質は落とさずにコストダウンを実現します。
            </Text>
          </Stack>
        </Stack>
        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={2}>
            <Image src={ImprovementImage} layout="fill" alt="改善のイメージ" />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              サイズ調整での配送費削減など
              <br />
              多角的な視点での改善提案
            </Text>
            <Box mt="1.5rem" />
            <Text
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              配送費や組み立て工数を削減できる仕様のご提案など、資材単体ではなく資材に関わるすべての工程を考慮し、改善提案を行います。
            </Text>
          </Stack>
        </Stack>
        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row-reverse' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={31 / 15}>
            <Image
              src={AllAtOnceImage}
              layout="fill"
              alt="shizaiのプロフェッショナルな人のイメージ"
            />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              豊富な専門知識で
              <br />
              貴社のイメージを形にします
            </Text>
            <Box mt="1.5rem" />
            <Text
              textStyle="body1"
              color="textGray.60"
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              プロの迅速なサポートで、作成の手間を最小に。
            </Text>
          </Stack>
        </Stack>

        <Box
          mt={{
            base: '3rem',
            md: '3rem',
            lg: '4.5rem',
          }}
        />

        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          spacing="2rem"
          align="center"
        >
          <AspectRatio width={descriptionImageWidth} ratio={31 / 15}>
            <Image src={SustainableImage} layout="fill" alt="サステナブルな資材のイメージ" />
          </AspectRatio>
          <Stack direction="column" width={descriptionImageWidth}>
            <Text as="h3" textStyle="h2" align={{ base: 'center', md: 'center', lg: 'start' }}>
              サステナブルな取り組み
            </Text>
            <Box mt="1.5rem" />
            <Text
              textStyle="body1"
              color="textGray.60"
              width={descriptionImageWidth}
              textAlign={{ base: 'center', md: 'center', lg: 'left' }}
            >
              製造で消費した木を自然に戻すため、植樹活動の支援を行っています。その他、環境に優しいインキの使用なども行っています。
            </Text>
            <Box mt="1.5rem" />
            <Flex
              flexDirection="column"
              width={{ base: '100%', md: '100%', lg: 'inherit' }}
              alignItems={{ base: 'stretch', md: 'stretch', lg: 'start' }}
            >
              <LinkBox
                onClick={() => {
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: 'サステナブルパッケージについて',
                  });
                }}
              >
                <LinkOverlay href={blogs.sustainable} isExternal>
                  <Button
                    variant="outline"
                    height={{
                      base: '40px',
                      md: '40px',
                      lg: '3rem',
                    }}
                    w={{ base: '100%', md: '100%' }}
                    maxW="100%"
                    minWidth="initial"
                  >
                    サステナブルパッケージについて
                  </Button>
                </LinkOverlay>
              </LinkBox>
            </Flex>
          </Stack>
        </Stack>

        <SectionSpacer />

        <Center flexDirection="column">
          <Text as="h2" textStyle="h1" align={{ base: 'center', md: 'center', lg: 'start' }}>
            shizaiでムダなコストを削減しよう
          </Text>
          <Box mt={{ base: '2rem', md: '2rem', lg: '3rem' }} />
          <Stack
            direction="row"
            spacing={{ base: 0, md: 0, lg: '7rem' }}
            width={{ base: '100%', md: '100%', lg: 'auto' }}
          >
            <Center flexDirection="column" display={{ base: 'none', md: 'none', lg: 'flex' }}>
              <Image
                src={LookForPackageImage}
                width={400}
                height={376}
                alt="取り扱い資材を見てみる"
              />
              <Box mt="1rem" />
              <Text>取り扱い資材を見てみる</Text>
              <Box mt="2rem" />
              <LinkBox
                onClick={() =>
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: '資材一覧へ',
                    value: 'consultation',
                  })
                }
              >
                <Link href={paths.quotation} passHref>
                  <Button height="4.5rem" variant="outline">
                    <Flex direction="column">
                      <Text>資材一覧へ</Text>
                    </Flex>
                  </Button>
                </Link>
              </LinkBox>
            </Center>
            <Center flexDirection="column" width={{ base: '100%', md: '100%', lg: 'auto' }}>
              <AspectRatio ratio={223 / 181} width={{ base: '100%', md: '100%', lg: 464 }}>
                <Image
                  src={ContactExpertImage}
                  layout="fill"
                  alt="shizaiでできることをもっと知りたい"
                />
              </AspectRatio>
              <Box mt="1rem" />
              <Text
                display={{
                  base: 'none',
                  md: 'none',
                  lg: 'block',
                }}
              >
                shizaiでできることをもっと知りたい
              </Text>
              <Box mt="2rem" />

              <LinkBox
                onClick={() =>
                  sendClickLog({
                    category: LogCategories.TOP,
                    label: '資料ダウンロード',
                    value: 'download_service_document',
                  })
                }
                width={{
                  base: '100%',
                  md: '100%',
                  lg: 'auto',
                }}
              >
                <Link href={paths.inquiryServiceDocument} passHref>
                  <ResponsiveButton width={{ base: '100%', md: '100%', lg: '320px' }}>
                    <Flex direction="column">
                      <Text>資料ダウンロード</Text>
                    </Flex>
                  </ResponsiveButton>
                </Link>
              </LinkBox>
            </Center>
          </Stack>
        </Center>
      </Box>
      <Box mt="4rem" />
    </Box>
  );
};

export default Index;
